<template>
  <organisms-advantages-push v-if="advantagesPush" v-bind="advantagesPush" />
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { PrismicState } from '~/store/prismic/state'

export default defineNuxtComponent({
  name: 'AdvantagesPushComponent',
  computed: {
    ...(mapGetters({
      advantagesPush: 'prismic/advantagesPush',
    }) as {
      advantagesPush: () => PrismicState['advantagesPush']
    }),
  },
})
</script>

<style lang="scss">
.advantages-push.-left {
  margin-top: april-rem($spacing-2xl);

  @media #{$mq-medium} {
    margin-top: april-rem($spacing-4xl);
  }

  .ui-wrapper {
    .advantages-push-content {
      ul {
        align-items: flex-start;
        display: flex;
        gap: april-rem(12);

        @media #{$mq-medium} {
          align-items: center;
          gap: april-rem(24);
        }

        li {
          flex: 1 0 0;
          padding: 0;

          span {
            max-width: none;
          }
        }
      }
    }
  }
}
</style>
